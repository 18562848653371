<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-modal
      id="wf-config"
      size="xl"
      header-class="colorz"
      :hide-footer="true"
      no-close-on-esc
      no-close-on-backdrop
      centered>

      <template #modal-header>
        <h5 class="text-white m-0">Workflow Configuration</h5>
        <div class="float-right">
          <i class="fa fa-times" size="sm" @click="$bvModal.hide('wf-config')" style="cursor: pointer"/>
        </div>
      </template>

      <div class="container-fluid p-5 mb-5" style="background-color: #EFF0F4;">
        <div class="row">
          <h5>Build Workflow</h5>
        </div>
        <div class="row">
          <p>Setup the paramenters to build our your workflow</p>
        </div>
        <div class="row">
          <div class="col-5 col-md-5 mb-3">
            <label class="form-label font-weight-bold" for="name">Name</label>
            <input class="form-control input-group input-group-sm" v-model="config.name" type="text" id="name" placeholder="Product/Plan Name">
          </div>
          <div class="col-4 col-md-4 mb-3">
            <label class="form-label font-weight-bold" aria-label="select-input">Search Type</label>
            <select class="form-control form-select" v-model="config.type" id="select-input">
              <option value="">Select Search Type</option>
              <option value="brand">Brand</option>
              <option value="photo">Photo</option>
              <option value="likeness">Likeness</option>
              <option value="video">Video</option>
            </select>
          </div>
            <div class="col-3 col-md-3 mb-3">
                <label class="form-label font-weight-bold" aria-label="price">Price</label>
                <b-input-group prepend="$" class="mb-2">
                    <b-form-input type="text" v-mask="mask" class="form-control input-group input-group-sm" v-model="config.price" id="price" aria-label="0.00"></b-form-input>
                </b-input-group>
            </div>
        </div>
        <div class="row">
          <div class="col-4 col-md-4">
            <label class="form-label font-weight-bold" for="minscore">Minimum Passing Score</label>
            <input class="form-control input-group input-group-sm" v-model="config.minScore" :min="minScore" step="10" :max="maxScore" type="number" id="minscore" placeholder="0.0">
          </div>
          <div class="col-4 col-md-4">
            <label class="form-label font-weight-bold" label-for="maxscore">Maximum Passing Score <i class="fa fa-info-circle" v-b-popover.hover.top="'The total sum of the Workflow steps should be less than or equal to the Maximum Passing Score.'" title="Maximum Passing Score" /></label>
            <b-form-input class="form-control input-group input-group-sm" v-model="config.maxScore" :state="valueValid" step="10" :min="minScore" :max="maxScore" type="number" id="maxscore" placeholder="0.0" />
          </div>
          <div class="col-4 col-md-4">
            <label class="form-label font-weight-bold" aria-label="runTime">Run Time</label>
            <select class="form-control form-select" v-model="config.runTime" id="runTime">
              <option value="">Select Time</option>
              <option value="one_time">One Time</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="bi_weekly">Bi-Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="quaterly">Quarterly</option>
            </select>
          </div>
        </div>
      </div>

      <div class="container p-4">
        <div class="row">
          <div class="col-8 col-md-8 my-auto"><h6 class="m-0">Define Workflow Steps</h6></div>
          <div class="col-4 col-md-4 ">
            <b-form-select class="form-control form-select" v-model="step" :options="widgets" value-field="_id" text-field="widget" @change="stepSelect" id="steps">
              <template #first>
                <b-form-select-option :value="''" disabled>Select Step</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
        <div class="row mt-3 d-flex justify-content-center">
          <div class="col-12 col-md-12">
            <draggable class="list-group" tag="ul" v-model="config.steps">
              <li class="list-group-item container" v-for="( step, index ) in config.steps" :key="index" >
                <div class="row" style="" @mouseover="showIndex = index" @mouseleave="showIndex = null">
                  <div class="col-1 my-auto">
                    <transition enter-active-class="animate__animated animate__fadeInLeft animate__faster" >
                      <i class="fa fa-sort" style="cursor: pointer" v-if="index === showIndex" />
                    </transition>
                    <p class="my-auto" v-if="showIndex !== index">{{ index + 1 }}</p>
                  </div>
                  <div class="col-4 my-auto"><span><i :class="{ 'fa fa-image' : step.key === 'img-widget', 'fa fa-file-alt' : step.key === 'txt-widget', 'fa fa-play' : step.key === 'vid-widget' }" class="text-muted align-middle me-2"></i> {{ step.widget }}</span></div>
                  <div class="col-2 my-auto">
                    <b-form-input class="align-middle pb-0" style="border: none !important; box-shadow: none !important;" v-model="step.value" @change="sum" :min="0" step="10" :max="maxScore" type="number" placeholder="Select Value"></b-form-input>
<!--                    <small class="text-muted">Value</small>-->
                  </div>
                  <div class="col-5 text-center my-auto">
                    <b-button variant="outline-warning btn-sm rounded-pill btn-icon mr-1" v-b-tooltip.top title="Edit Step" @click="row_ting = step; sel_index = index;" v-b-modal="step.key"><i class="fa fa-pencil" size="sm"></i></b-button>
                    <b-button variant="outline-danger btn-sm rounded-pill btn-icon" @click="removeStep( index )" v-b-tooltip.top title="Delete Step"><i class="fa fa-trash" size="sm"></i></b-button>
                  </div>
                </div>
              </li>
            </draggable>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-8 col-md-8 my-auto"><h6 class="m-0">Available Automation Actions</h6></div>
          <div class="col-4 col-md-4 ">
            <b-form-select class="form-control form-select" v-model="actions" :options="automation" value-field="_id" text-field="action" @change="actionSelect" id="actions">
              <template #first>
                <b-form-select-option :value="''" disabled>Select Action</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
        <div class="row mt-3">
          <li class="list-group-item container" v-for="( auto, index ) in config.actions" :key="auto._id" >
            <div class="row">
              <div class="col-2 my-auto">
                <b-form-select v-model="auto.result" :options="result">
                  <template #first>
                    <b-form-select-option :value="''" disabled>Select Action</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-3 my-auto">{{ auto.action }}</div>
              <div class="col-2 my-auto">
                <b-form-input class="align-middle pb-0" style="border: none !important; box-shadow: none !important;" v-model="auto.value" :min="0" step="10" :max="100" type="number" placeholder="Select Value"></b-form-input>
              </div>
              <div class="col-5 text-center my-auto">
                <b-button variant="outline-warning btn-sm rounded-pill btn-icon mr-1" v-b-tooltip.top title="Edit Step" @click="row_ting = step; sel_index = index;" v-b-modal="step.key"><i class="fa fa-pencil" size="sm"></i></b-button>
                <b-button variant="outline-danger btn-sm rounded-pill btn-icon" v-b-tooltip.top title="Delete Step" @click="removeAction( index )"><i class="fa fa-trash" size="sm"></i></b-button>
              </div>
            </div>
          </li>
        </div>

        <div class="row mt-5 justify-content-end">
          <div class="col-1 col-md-1">
            <b-button variant="outline-success"  @click="apply" size="sm" v-text="'APPLY'" />
          </div>
          <div class="col-2 col-md-2">
            <b-button varieant="dark" @click="$bvModal.hide('wf-config');" size="sm" v-text="'CANCEL'" />
          </div>
        </div>
      </div>
      <widget :data="row_ting" :index="Number(sel_index)" v-on:save_img_config="addImageConfig" v-on:text_img_config="addTextConfig" />
    </b-modal>
  </div>
</template>

<script>
import widget from '@/components/workflow/widget'
import swal from "sweetalert";
import axios from "axios";
import draggable from 'vuedraggable'
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue'
import VueMask from 'v-mask'
Vue.use(VueMask)
import createNumberMask from 'text-mask-addons/dist/createNumberMask';


export default {
  name: "configuration",

  data() {
    return {
        step: '',
        products: '',
        showIndex: null,
        stepCurrentPage: 1,
        actionCurrentPage: 1,
         mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: true,
            allowNegative: false,
        }),
        perPage: 3,
        widgets: [],
        automation: [],
        valueValid: null,
        minScore: 0,
        maxScore: 100,
        actions: '',
        row_ting: {},
        sel_index: '',
        items: [],
        result: [ { value: 'pass', text: 'Pass' }, { value: 'fail', text: 'Fail' } ]
    }
  },
  props: {
    config: {
      type: Object,
      required: true,
    }
  },
  components: {
    widget,
    draggable,
  },
    created() { this.getWidgets(); this.getActions(); },
    watch: {
    selStep() {
      return this.step
    },
    "config.maxScore"() {
      if ( this.config.maxScore && !this.valueValid ) {
        this.valueValid = true
      }
      if ( !this.config.maxScore ) {
        this.valueValid = null
      }
    }
  },
  computed: {
    stepFields() {
      return [
        {
          key: 'steps',
          label: 'Steps',
          sortable: false
        },
        {
          key: 'widget',
          label: 'Widget',
          sortable: false
        },
        {
          key: 'value',
          label: 'Value',
          sortable: false
        },
        {
          key: 'actions',
          label: 'Actions',
          sortable: false
        },
      ]
    },
    actionFields() {
        return [
          {
            key: 'results',
            label: 'Results',
            sortable: false
          },
          {
            key: 'action',
            label: 'Action',
            sortable: false
          },
          {
            key: 'value',
            label: 'Value',
            sortable: false
          },
          {
            key: 'actions',
            label: 'Actions',
            sortable: false
          },
        ]
      },
  },
  methods: {
    removeStep( index ) {
      this.config.steps.splice( index, 1 )
      this.$bvToast.toast('Workflow step deleted successfully', {
        title: `Success: Workflow Step`,
        variant: `success`,
        solid: true
      })
    },
    removeAction( index ) {
      this.config.actions.splice( index, 1 )
      this.$bvToast.toast('Workflow action deleted successfully', {
        title: `Success: Workflow Action`,
        variant: `success`,
        solid: true
      })
    },
    getWidgets() {
      axios.get(`${this.$root.serverUrl}/admin/widget`).then((resp) => {
        this.widgets = resp.data.data || []
      })
    },
    getActions() {
      axios.get(`${this.$root.serverUrl}/admin/automation`).then((resp) => {
        this.automation = resp.data.data || []
      })
    },
    sum() {
      let data
      if ( this.config.steps ) { data = this.config.steps.reduce(( sum, curr ) => sum + Number( curr.value ), 0 ) }
      if ( data > this.config.maxScore ) {
        this.$bvToast.toast('Total step value exceed Maximum Passing Score', {
          title: `Warning: Maximum Passing Score exceeded.`,
          variant: `warning`,
          solid: true
        })
        return false
      } else {
        return true
      }
    },
    validate() {
      if ( !this.config.name || this.config.name === '' ) {
        this.$bvToast.toast('Workflow name is empty.', {
          title: `Warning: Workflow Name.`,
          variant: `warning`,
          solid: true
        })
        return false
      } else if ( !this.config.type || this.config.type === '' ) {
        this.$bvToast.toast('Workflow Search type is empty.', {
          title: `Warning: Search Type.`,
          variant: `warning`,
          solid: true
        })
        return false
      } else if ( !this.config.minScore || this.config.minScore === 0 ) {
        this.$bvToast.toast('Workflow Minimum Score not selected.', {
          title: `Warning: Minimum Score`,
          variant: `warning`,
          solid: true
        })
        return false
      } else if ( !this.config.maxScore || this.config.maxScore === 0 ) {
        this.$bvToast.toast('Workflow Maximum Score not selected.', {
          title: `Warning: Maximum Score`,
          variant: `warning`,
          solid: true
        })
        return false
      } else if ( !this.config.runTime || this.config.runTime === '' ) {
        this.$bvToast.toast('Workflow Run Time not selected.', {
          title: `Warning: Run Time`,
          variant: `warning`,
          solid: true
        })
        return false
      } else if ( !this.config.steps || this.config.steps.length === 0 ) {
        this.$bvToast.toast('No Workflow Step is selected.', {
          title: `Warning: Workflow Steps`,
          variant: `warning`,
          solid: true
        })
        return false
      } else if ( !this.config.actions || this.config.actions.length === 0 ) {
        this.$bvToast.toast('No Workflow Action is selected.', {
          title: `Warning: Workflow Action`,
          variant: `warning`,
          solid: true
        })
        return false
      } else {
        return true
      }
    },
    validateValue() {
      if ( this.config.steps === undefined ) {
        this.valueValid = false
        this.$bvToast.toast('Maximum Passing Score not set.', {
          title: `Warning: Maximum Score`,
          variant: `warning`,
          solid: true
        })
        return false
      } else {
        return true
      }
    },
    addImageConfig( data, index ) {
      if ( !this.config.steps[index].widget_options ) {
        this.config.steps[index].widget_options = { }
        this.config.steps[index].widget_options = data
      } else {
        this.config.steps[index].widget_options = data
      }
    },
    addTextConfig( data, index ) {
      if ( !this.config.steps[index].widget_options ) {
        this.config.steps[index].widget_options = { }
        this.config.steps[index].widget_options = data
      } else {
        this.config.steps[index].widget_options = data
      }
    },
    apply() {
      if ( this.sum() ) {
        if ( this.config._id ) {
          axios.put(`${this.$root.serverUrl}/admin/products`, this.config).then((res) => {
            if (res && res.data.updated) {
                swal({ title: 'Workflow Updated', text: 'Workflow updated successfully.', icon: 'success' }).then(() => { this.$emit('close_config') })
            }
          }).catch(( err ) => {
            swal({ title: 'Error', text: err, icon: 'error' })
          })
        } else {
          if ( this.validate() ) {
            axios.post(`${this.$root.serverUrl}/admin/products`, this.config).then((resp) => {
              if (resp && resp.data._id) {
                  swal({ title: 'Workflow Created', text: 'Workflow saved successfully!', icon: 'success' }).then(() => { this.$emit('close_config') })
              }
            })
          }
        }
      }
    },
    actionSelect() {
      if ( !this.config.actions ) {
        this.config.actions = []
      }

      for ( let data of this.automation ) {
        if ( data._id === this.actions ) {
          this.config.actions.push( Object.assign({ result: '', value: '' }, data ) )
          this.actions = ''
        }
      }
    },
    stepSelect() {
      if ( this.config.maxScore ) {
        if ( !this.config.steps ) {
          this.config.steps = []
        }
        for ( let val of this.widgets ) {
          if ( val._id === this.step ) {
            val.widget_options = val.key === 'img-widget' ? Object.assign({ allowed_algorithms: [] } ) : val.key === 'txt-widget' ? Object.assign({ elements: [], engines: [] } ) : val.key === 'vid-widget' ? Object.assign({ allowed_algorithms: [] } ) : { }
            val.step_id = uuidv4();
            this.config.steps.push(Object.assign({}, val))
            this.step = ''
          }
        }
      } else {
        this.validateValue()
        this.step = ''
      }
      this.$forceUpdate()
    },
  }
}
</script>

<style scoped>
  /deep/ .colorz {
    background-color: #444444;
    color: white;
  }
  /deep/ .modal-body {
    padding: 0 !important;
  }
</style>
