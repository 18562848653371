<template>
  <div>
<!--    Image Widget-->
    <b-modal
      id="img-widget"
      size="lg"
      :hide-footer="true"
      centered>
      <template #modal-header show>
        <h5 class="m-0">Edit Widget <span class="text-black-50">Workflow Engine</span> </h5>
      </template>

      <div class="container mt-4">
        <div class="card border-dark" style="background-color: #EFF0F4">
          <div class="card-body">
            <div class="row">
              <div class="col-8 col-md-8 border-right border-dark">
                <div class="row">
                  <div class="col-12">
                    <h6 class="m-0">Image Search Widget</h6>
                    <p class="text-black-50">Searches uploaded images for a match</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <label class="form-label font-weight-bold" label-for="image_types">Allowed Image Types</label>
                    <multiselect v-model="widget_options.img_file_types" :multiple="true" :options="file_types" id="image_types"></multiselect>
<!--                    <input class="form-control input-group input-group-sm" size="sm" type="text" id="name" placeholder="Product/Plan Name">-->
                  </div>
                  <div class="col-4">
                    <label class="form-label font-weight-bold" for="maxImage">Max Allowed Image</label>
                    <input class="form-control input-group input-group-sm" v-model="widget_options.image_count" type="number" id="maxImage" placeholder="0">
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-6">
                    <label class="form-label font-weight-bold" for="minScore">Minimum Confidence Score</label>
                    <input class="form-control input-group input-group-sm" v-model="widget_options.min_confidence_score" size="sm" type="number" id="minScore" placeholder="0.0">
                  </div>
                  <div class="col-6">
                    <label class="form-label font-weight-bold" for="maxScore">Maximum Confidence Score</label>
                    <input class="form-control input-group input-group-sm" v-model="widget_options.max_confidence_score" type="number" id="maxScore" placeholder="0.0">
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 col-md-12">
                    <b-form-group
                      label="Allowed Algorithms"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio
                        v-for="option in options"
                        v-model="widget_options.allowed_algorithms"
                        :key="option.value"
                        :value="option.value"
                        stacked
                        :aria-describedby="ariaDescribedby"
                      >
                        {{ option.text }}
                      </b-form-radio>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <div class="col-4 col-md-4">
                <h6 class="m-0 border-bottom border-dark pb-3">Image Search Widget</h6>
                <div class="row mt-2">
                  <div class="col-12 col-md-12">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non nunc auctor, bibendum mi id, mollis massa. Sed in neque in turpis dignissim imperdiet. Mauris maximus erat ac tortor facilisis, eget eleifend turpis laoreet.
                      Etiam consequat eros nec quam sodales, sit amet posuere lectus mattis. Etiam molestie urna id tellus suscipit egestas.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5 justify-content-end">
          <div class="col-2 col-md-2 pr-0">
            <b-button class="btn btn-translucent-success" @click="image_save" size="sm" v-text="'SAVE'" />
          </div>
          <div class="col-2 col-md-2">
            <b-button class="btn btn-translucent-dark" size="sm" v-text="'CANCEL'" @click="$bvModal.hide('img-widget')" />
          </div>
        </div>
      </div>
    </b-modal>

<!--    Video Widget-->
    <b-modal
      id="vid-widget"
      size="lg"
      :hide-footer="true"
      centered>
      <template #modal-header show>
        <h5 class="m-0">Edit Widget <span class="text-black-50">Workflow Engine</span> </h5>
      </template>

      <div class="container mt-4">
        <div class="card border-dark" style="background-color: #EFF0F4">
          <div class="card-body">
            <div class="row">
              <div class="col-8 col-md-8 border-right border-dark">
                <div class="row">
                  <div class="col-12">
                    <h6 class="m-0">Video Search Widget</h6>
                    <p class="text-black-50">Searches uploaded videos for a match</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-7">
                    <label class="form-label font-weight-bold" label-for="video_types">Allowed Video Types</label>
                    <multiselect v-model="widget_options.video_file_types" :multiple="true" :options="video_file_types" id="video_types"></multiselect>
                  </div>
                  <div class="col-5">
                    <label class="form-label font-weight-bold" for="vidMaxImage">Max Allowed Videos</label>
                    <input class="form-control input-group input-group-sm" v-model="widget_options.video_count" type="number" id="vidMaxImage" placeholder="0">
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-6">
                    <label class="form-label font-weight-bold" for="vidMinScore">Minimum Confidence Score</label>
                    <input class="form-control input-group input-group-sm" v-model="widget_options.min_confidence_score" size="sm" type="number" id="vidMinScore" placeholder="0.0">
                  </div>
                  <div class="col-6">
                    <label class="form-label font-weight-bold" for="vidMaxScore">Maximum Confidence Score</label>
                    <input class="form-control input-group input-group-sm" v-model="widget_options.max_confidence_score" type="number" id="vidMaxScore" placeholder="0.0">
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 col-md-12">
                    <b-form-group
                      label="Detection Algorithm"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio
                        v-for="option in options"
                        v-model="widget_options.allowed_algorithms"
                        :key="option.value"
                        :value="option.value"
                        stacked
                        :aria-describedby="ariaDescribedby"
                      >
                        {{ option.text }}
                      </b-form-radio>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <div class="col-4 col-md-4">
                <h6 class="m-0 border-bottom border-dark pb-3">Video Search Widget</h6>
                <div class="row mt-2">
                  <div class="col-12 col-md-12">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non nunc auctor, bibendum mi id, mollis massa. Sed in neque in turpis dignissim imperdiet. Mauris maximus erat ac tortor facilisis, eget eleifend turpis laoreet.
                      Etiam consequat eros nec quam sodales, sit amet posuere lectus mattis. Etiam molestie urna id tellus suscipit egestas.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5 justify-content-end">
          <div class="col-2 col-md-2 pr-0">
            <b-button class="btn btn-translucent-success" @click="video_save" size="sm" v-text="'SAVE'" />
          </div>
          <div class="col-2 col-md-2">
            <b-button class="btn btn-translucent-dark" size="sm" v-text="'CANCEL'" @click="$bvModal.hide('vid-widget')" />
          </div>
        </div>
      </div>
    </b-modal>

<!--    Text Widget-->
    <b-modal
      id="txt-widget"
      size="lg"
      :hide-footer="true"
      centered>
      <template #modal-header show>
        <h5 class="m-0">Edit Widget <span class="text-black-50">Workflow Engine</span> </h5>
      </template>

      <div class="container mt-4">
        <div class="card border-dark" style="background-color: #EFF0F4">
          <div class="card-body">
            <div class="row">
              <div class="col-8 col-md-8 border-right border-dark">
                <div class="row">
                  <div class="col-12">
                    <h6 class="m-0">Text Search Widget</h6>
                    <p class="text-black-50">Search page for matching text</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6 col-md-6">
                    <b-form-group
                      label="Targeted Elements"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-checkbox
                        v-for="option in tar_options"
                        v-model="widget_options.elements"
                        :key="option.value"
                        :value="option.value"
                        :aria-describedby="ariaDescribedby"
                      >
                        {{ option.text }}
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
<!--                  <div class="col-6 col-md-6">-->
<!--                    <b-form-group-->
<!--                      label="Use Engines"-->
<!--                      v-slot="{ ariaDescribedby }"-->
<!--                    >-->
<!--                      <b-form-checkbox-->
<!--                        v-for="option in engine_options"-->
<!--                        v-model="widget_options.engines"-->
<!--                        :key="option.value"-->
<!--                        :value="option.value"-->
<!--                        :aria-describedby="ariaDescribedby"-->
<!--                      >-->
<!--                        {{ option.text }}-->
<!--                      </b-form-checkbox>-->
<!--                    </b-form-group>-->
<!--                  </div>-->
                </div>
                <div class="row mt-2">
                  <div class="col-6">
                    <label class="form-label font-weight-bold" for="minTextScore">Minimum Confidence Score</label>
                    <input class="form-control input-group input-group-sm" v-model="widget_options.min_confidence_score" size="sm" type="number" id="minTextScore" placeholder="0.0">
                  </div>
                  <div class="col-6">
                    <label class="form-label font-weight-bold" for="maxTextScore">Maximum Confidence Score</label>
                    <input class="form-control input-group input-group-sm" v-model="widget_options.max_confidence_score" type="number" id="maxTextScore" placeholder="0.0">
                  </div>
                </div>
              </div>

              <div class="col-4 col-md-4">
                <h6 class="m-0 border-bottom border-dark pb-3">Text Search Widget</h6>
                <div class="row mt-2">
                  <div class="col-12 col-md-12">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non nunc auctor, bibendum mi id, mollis massa. Sed in neque in turpis dignissim imperdiet. Mauris maximus erat ac tortor facilisis, eget eleifend turpis laoreet.
                      Etiam consequat eros nec quam sodales, sit amet posuere lectus mattis. Etiam molestie urna id tellus suscipit egestas.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5 justify-content-end">
          <div class="col-2 col-md-2 pr-0">
            <b-button class="btn btn-translucent-success" @click="text_save" size="sm" v-text="'SAVE'" />
          </div>
          <div class="col-2 col-md-2">
            <b-button class="btn btn-translucent-dark" size="sm" v-text="'CANCEL'" @click="$bvModal.hide('txt-widget')" />
          </div>
        </div>
      </div>

    </b-modal>

  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import swal from 'sweetalert'

export default {
  name: "widget",
  components:{ Multiselect },
  data() {
    return {
      widget_options: {  },
      file_types: [ '.jpg', '.jpeg', '.tif', '.tiff', '.png', '.bmp', '.gif', '.eps', '.raw', '.cr2', '.nef', '.orf', '.sr2' ],
      video_file_types: [ '.mp4', '.mov', '.wmv', '.avi', '.flv', '.f4v', '.swf', '.mkv', '.webm', '.mp2', '.mpeg', '.mpe', '.mpv', '.ogg', '.m4p', '.qt' ],
      options: [
          { text: 'Template Matching', value: 'template' },
          { text: 'Feature Matching', value: 'sift' },
          { text: 'Convolutional Neural Network', value: 'cnn' }
        // { text: 'Brute-Force Matching', value: 'brute_force_matching' },
        // { text: 'ORB Matching', value: 'orb_matching' },
        // { text: 'FLANN Matching', value: 'flann_matching' },
        // { text: 'KNN Matching', value: 'knn_matching' }
      ],
      engine_options: [
        { text: 'NLP', value: 'nlp' },
        { text: 'LSI', value: 'lsi' },
      ],
      tar_options: [
        // { text: 'Labels', value: 'labels' },
        // { text: 'Spans', value: 'spans' },
        // { text: 'Paragraphs', value: 'paragraphs' },
        // { text: 'Headers', value: 'headers' },
        // { text: 'Divs', value: 'divs' },
        { text: 'HTML OCR', value: 'h_ocr' },
        { text: 'Images OCR', value: 'i_ocr' },
      ]
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
    }
  },
  watch: {
    data() {
      this.widget_options = Object.assign({}, this.data.widget_options )
    }
  },
  methods: {
    image_save() {
      this.$emit('save_img_config', this.widget_options, this.index )
      swal({ title: 'Widget Updated', text: 'Image widget updated successfully.', icon: 'success' }).then(() => {
        this.$bvModal.hide('img-widget')
      })
    },
    video_save() {
      this.$emit('save_img_config', this.widget_options, this.index )
      swal({ title: 'Widget Updated', text: 'Video widget updated successfully.', icon: 'success' }).then(() => {
        this.$bvModal.hide('vid-widget')
      })
    },
    text_save() {
      this.$emit('text_img_config', this.widget_options, this.index )
      swal({ title: 'Widget Updated', text: 'Text widget updated successfully.', icon: 'success' }).then(() => {
        this.$bvModal.hide('txt-widget')
      })
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>

</style>
