<template>
    <div class="container p-5 mt-5">
        <div class="row d-flex align-content-center ">
            <div class="col-12 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Workflow Engine <b-button variant="outline-success ml-2" @click="selected" v-b-modal.wf-config>Create Workflow</b-button></h5>
                        <div class="table-responsive">
                            <b-table
                                show-empty
                                class="table table-hover"
                                :fields="fields"
                                :items="items"
                                :empty-text="'No Workflows created yet.'"
                                :per-page="perPage"
                                :current-page="currentPage">

                                <template #empty="scope">
                                    <p class="fs-sm text-sm-center">{{ scope.emptyText }} <strong>Create</strong> one now.</p>
                                </template>

                                <template v-slot:cell(actions)="row">
                                    <b-button variant="outline-warning btn-sm rounded-pill btn-icon mr-1" @click="selected(row.item)" v-b-modal.wf-config v-b-tooltip.top title="Edit Workflow"> <i class="fa fa-pencil" size="sm"></i></b-button>
                                    <b-button variant="outline-danger btn-sm rounded-pill btn-icon" @click="removeWF(row.item)" v-b-tooltip.top title="Delete Workflow"><i class="fa fa-trash" size="sm"></i></b-button>
                                </template>

                            </b-table>

                            <div class="d-flex justify-content-center" v-if="items && items.length >= 5">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="items.length"
                                    :per-page="perPage"
                                ></b-pagination>
                            </div>
                        </div>

                    </div>
                </div>
                <w-f-config :config="selected_data" v-on:close_config="getAll();  $bvModal.hide('wf-config')" />
            </div>
        </div>
    </div>
</template>

<script>
import WFConfig from '@/components/workflow/configuration'
import axios from "axios";
import Swal from 'sweetalert'
export default {
    name: "Workflow",
    data() {
        return {
            currentPage: 1,
            perPage: 5,
            selected_data: { },
            items: []
        }
    },
    created() {
        this.getAll()
        this.loadProducts()
    },
    components: {
        WFConfig
    },
    computed: {
        fields() {
            return [
                {
                    key: 'name',
                    label: 'Name',
                    sortable: false
                },
                {
                    key: 'type',
                    label: 'Type',
                    sortable: false
                },
                {
                    key: 'actions',
                    label: 'Actions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        newWork() {
            let config = {
                type: '',
                runTime: '',
                steps: []
            }
            this.selected_data = config
        },
        removeWF(obj) {
            Swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                buttons:['No','Yes']
            }).then((result) => {
                if (result) {
                    axios.delete(`${this.$root.serverUrl}/admin/products/contentshieldPlan/${obj._id}`).then(( resp ) => {
                        if (resp && !resp.data.message) {
                            Swal(
                                'Deleted!',
                                `Your Workflow, ${obj.name} has been deleted.`,
                                'success'
                            ).then(() => this.getAll() )
                        } else {
                            Swal(
                                'Warning!',
                                `${resp.data.message}`,
                                'warning'
                            )
                        }
                    })
                }
            })
        },
        async loadProducts() {
            try {
                const data = await axios.get(`${this.$root.serverUrl}/admin/products/`);
                this.products = data.data.data;
            } catch(err){
                Swal({title:"Error getting Products", text: err, icon: "error"});
            }
        },
        selected(obj) {
            if ( obj._id ) {
                this.selected_data = Object.assign({}, obj)
            } else {
                let product_id
                this.products.forEach((item) => {
                    if ( item.Identifier === 'contentcatcher' )
                        product_id = item._id
                })
                let data = {
                    runTime: '',
                    ProductId: product_id
                }
                this.selected_data = Object.assign({},  data)
            }
        },
        getAll() {
            axios.get(`${this.$root.serverUrl}/admin/products/contentshieldPlans`).then((resp) => {
                this.items = resp.data.data || []
            }).finally(() => {
                this.$root.preloader = false;
            })
        },
    }
}
</script>

<style scoped>

</style>
